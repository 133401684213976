import * as React from "react";
import styled, { keyframes } from "styled-components";
import "@fontsource/faster-one";
import { graphql } from "gatsby";

import Game from "../components/game";
import Scoreboard from "../components/scoreboard";

export interface Game {
  name: string;
  isFinished: boolean;
  result: Clown[];
}

export interface Clown {
  name: string;
  points: number;
}

interface GameList {
  id: string;
  gameList: Game[];
}
interface QueryData {
  data: {
    allSanityGames: { nodes: GameList[] };
    allSanityClown: { nodes: Clown[] };
  };
}

const LandingSection = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;

  > h1 {
    text-align: center;
    font-family: "Faster One";
    font-weight: normal;
    text-transform: uppercase;
  }
`;

const textAnimation = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const clownAnimation = keyframes`
    0% {
      transform: translate3d(-120%, 0, 0)
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
`;

const BattleText = styled.div`
  opacity: 0;
  font-size: 10vw;
  @media (max-width: 768px) {
    font-size: 14vw;
  }

  animation-name: ${textAnimation};
  animation-duration: 1200ms;
  animation-fill-mode: forwards;
`;

const OfTheText = styled.div`
  opacity: 0;
  font-size: 5vw;
  line-height: 0.2;

  @media (max-width: 768px) {
    font-size: 10vw;
    line-height: 0.4;
  }

  animation-name: ${textAnimation};
  animation-duration: 1200ms;
  animation-delay: 1200ms;
  animation-fill-mode: forwards;
`;

const ClownText = styled.div`
  font-size: 16vw;
  opacity: 0;

  @media (max-width: 768px) {
    font-size: 18vw;
  }

  margin-top: 20px;
  color: #de2d2d;
  transform: translate3d(-120%, 0, 0);

  animation-name: ${clownAnimation};
  animation-duration: 600ms;
  animation-delay: 2400ms;
  animation-fill-mode: forwards;

  > span {
    &:nth-of-type(2) {
      color: #f0e052;
    }

    &:nth-of-type(3) {
      color: #63df17;
    }

    &:nth-of-type(4) {
      color: #0578ff;
    }

    &:nth-of-type(5) {
      color: #ff9900;
    }

    &:nth-of-type(6) {
      color: #ff7af2;
    }
  }
`;

const GameSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  font-family: Arial, Helvetica, sans-serif;

  > div {
    margin-right: 20px;
    margin-bottom: 20px;
    width: 29%;

    background: #f9f9f9;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
    }
  }

  ul {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;

    list-style: none;
  }
`;

const IndexPage = ({ data }: QueryData) => {
  const games =  data?.allSanityGames?.nodes[0].gameList;
  const clowns =  data?.allSanityClown?.nodes;

  const finishedGames = games?.filter((game) => {
    return game.isFinished;
  });

  const upcomingGames = games.filter((game) => {
    return !game.isFinished;
  });

  return (
    <main>
      <LandingSection>
        <h1>
          <BattleText>Battle</BattleText>
          <OfTheText>of the</OfTheText>
          <ClownText>
            <span>c</span>
            <span>l</span>
            <span>o</span>
            <span>w</span>
            <span>n</span>
            <span>s</span>
          </ClownText>
        </h1>
      </LandingSection>

      <GameSection>
        <div>
          <h2>Kommande spel</h2>
          {upcomingGames.map((game, index) => {
            return <Game game={game} key={`upcoming-${index}`} />;
          })}
        </div>

        <div>
          <h2>Avslutade spel</h2>
          {finishedGames.map((game, index) => {
            return <Game game={game} key={`finished-${index}`} />;
          })}
        </div>

        <div>
          <h2>Poängställning</h2>
          <Scoreboard games={finishedGames} clowns={clowns} />
        </div>
      </GameSection>
    </main>
  );
};

export default IndexPage;

export const query = graphql`
  query MyQuery {
    allSanityGames(filter: {title: {eq: "Game List"}}) {
      nodes {
        id
        gameList {
          isFinished
          result {
            name
          }
        }
      }
    }
    allSanityClown {
      nodes {
        name
        points
      }
    }
  }
`;
